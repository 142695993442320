import 'bootstrap/dist/css/bootstrap.min.css';
import 'flag-icon-css/css/flag-icons.css'

import { Button } from 'react-bootstrap';

import React, { useState } from 'react';

function App() {

	var nlanguage = window.navigator.userLanguage || window.navigator.language;

	let languageStart = "en"

	if(nlanguage === "fr-FR"){
		languageStart = "fr"
	}

	const [language, setLanguage] = useState(languageStart);

	let languages = [
		{
			name:"fr",
			icon:"fr"
		},
		{
			name:"en",
			icon:"gb"
		}
	]

	let videos = [
		{
			title:{
				fr:"Châteaudun au Moyen Âge",
				en:"Chateaudun in the Middle Ages"
			},
			video:{
				fr:"https://youtu.be/ldXqYqPBkhI",
				en:"https://youtu.be/yxPMZSdvrRQ"
			}
		},
		{
			title:{
				fr:"Châteaudun à l’épreuve de l'histoire",
				en:"Chateaudun put to the test of History"
			},
			video:{
				fr:"https://youtu.be/TSZSxSPujuk",
				en:"https://youtu.be/p1UvnPW0y7s"
			}
		},
		{
			title:{
				fr:"L’essor de la ville",
				en:"The rise of the city"
			},
			video:{
				fr:"https://youtu.be/TStuGIXIzNc",
				en:"https://youtu.be/fz8tnxoYb_U"
			}
		}
	]

	const openInNewTab = (url) => {
		const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
		if (newWindow) newWindow.opener = null
	  }

	let openLink = (l) => {
		//console.log("open link "+l)
		openInNewTab(l)
	}

	return (
		<div className="main">
			{videos.map((v) => 
				<Button
					onClick={(e) => openLink(v.video[language])}
					key={v.title[language]}
					className="w-50 m-2 btn btn-lg btn-primary"
				>
					{v.title[language]}
				</Button>
			)}
			<div style={{width:"50%", textAlign:"center", padding:"10px"}}>
				{languages.map((l) =>
					<a
						onClick={(e) => setLanguage(l.name)}
						key={l.name}
						style={{cursor:"pointer", margin:"10px"}}
					>
						<span className={"flag-icon flag-icon-"+l.icon}></span>
					</a>
				)}
			</div>

		</div>
	);
}

export default App;